import React, { useEffect, useState } from "react";
import axios from "axios";
import { login } from "../../script/login";
import AdminNavbar from "../../components/AdminNavbar";
import Footer from "../../components/Footer";
import MessageCard from "../../components/admin/MessageCard";

const AdminMessage = () => {

    const [data, setData] = useState([])

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = login()
        axios.post('https://apipambroise.lyceestvincent.fr/api/messages').then((result) => {
            setData(result.data)
        })
        
    }, [])

    if (data.length === 0 ){
        return ('Chargement')
    }

    return (
    <div>
        <AdminNavbar />
            <div className="admin-message">
                <h2 id='title-admin' >Vos message : </h2>
                <div className="message-container">
                    {data.map((message) => 
                        <MessageCard message={message} key={message.id} />
                    )}
                </div>
            </div>
        <Footer />
    </div>
    )
}

export default AdminMessage