import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

const NotFound = () => {
    return (
        <div>
            <Navbar />
            <div id='not-found'>
                <h2>Oops !</h2>
                <h3>Il semble que la page demandé n'existe pas</h3>
                <a href="/" > <AiOutlineArrowRight /> <span>Revenir en lieu sur</span> <AiOutlineArrowLeft /></a>
            </div>
            <Footer />
        </div>
    )
}

export default NotFound