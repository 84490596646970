import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Project from './pages/Project';
import NotFound from './pages/NotFound';
import LoginForm from './pages/LoginForm';
import Admin from './pages/Admin';
import AdminProject from './pages/admin/AdminProject';
import EditProject from './pages/admin/EditProject'
import AddProject from './pages/admin/AddProject';
import AdminMessage from './pages/admin/AdminMessage';
import './App.scss';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path={`/project/:id`} element={<Project />} />
                <Route path={`/admin/login`} element={<LoginForm />} />

                {/* Admin access */}

                <Route path={`/admin`} element={<Admin />} />
                <Route path={`/admin/projects`} element={<AdminProject />} />
                <Route path={`/admin/project/edit/:id`} element={<EditProject />} />
                <Route path={`/admin/add`} element={<AddProject />} />
                <Route path={`/admin/message`} element={<AdminMessage />} />

                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
