import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProjectCard from '../components/ProjectCard';

const Project = () => {

    const [data, setData] = useState([])

    useEffect(()=> {
        axios.get('https://apipambroise.lyceestvincent.fr/api/projects').then((result) => {
            setData(result.data)
        })
    }, [])

    if (data.length === 0 ){
        return (
            <div className='loading-projects'>
                <h3>Chargement des projets</h3>
            </div>
        )
    }

    return (
        <div id='projects' className='projects'>
            <h3>Mes projets</h3>
            <div className='project-container'>
            {data.map((project) => 
                <ProjectCard project={project} key={project.id} />
            )}
            </div>
        </div>
    );
};

export default Project;