import React from "react";

const ProjectCard = (props) => {
    return (
        <a href={'/project/' + props.project.id}>
            <div className='project-card'>
                <h4>{props.project.name}</h4>
                <p>{props.project.context}</p>
                <img src={"https://apipambroise.lyceestvincent.fr/images/"+ props.project.name + "/" + props.project.images[0].path} alt={props.project.name} />
            </div>
        </a>
    )
}

export default ProjectCard