import React, { useState } from "react";
import { login } from "../../script/login";
import axios from "axios";

const ProjectForm = (props) => {

    const [errors, setErrors] = useState({});

    const handleSubmit = (form) => {
        form.preventDefault()

        var bodyFormData = new FormData(form.target);

        axios.defaults.headers.common['Authorization'] = login()
        axios.defaults.headers['content-type'] = 'multipart/form-data'

        if ('Modifier' === props.action){
            axios.post(`https://apipambroise.lyceestvincent.fr/api/projects/${props.project.id}/update`, bodyFormData).then((result) => {
            if (typeof result.data === 'string') {
                window.location.replace("/admin/projects")
                console.log(result.data)
            } else {
                setErrors(result.data)
            }
        })
        } else {
            axios.post(`https://apipambroise.lyceestvincent.fr/api/project`, bodyFormData).then((result) =>  {
                if (result.status === 201){
                    window.location.replace("/admin/projects")
                } else {
                    setErrors(result.data) 
                }})
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-header">
                <div className="form-group">
                    <input type='text' name='name' id='name' required defaultValue={props.project && props.project.name} />
                    <label htmlFor='name' title="Nom" data-title='Nom' ></label>
                    {errors.name && <p className="errors">{errors.name}</p>}
                </div>
                <div className="form-group">
                    <input type='text' name='time' id='time' required defaultValue={props.project && props.project.time} />
                    <label htmlFor='time' title="Temps" data-title='Temps' ></label>
                    {errors.time && <p className="errors">{errors.time}</p>}
                </div>
            </div>
            <div className="form-group">
                <input type='text' name='context' id='context' required defaultValue={props.project && props.project.context} />
                <label htmlFor='context' title="Contexte" data-title='Contexte' ></label>
                {errors.context && <p className="errors">{errors.context}</p>}
            </div>
            <div className="form-group">
                <textarea name='description' placeholder="Description" defaultValue={props.project && props.project.description} ></textarea>
                {errors.description && <p className="errors">{errors.description}</p>}
            </div>
            <input type="file" id='file-input' name='image' />
            <label htmlFor="file-input" id='file-label' >Ajouter une image</label>
            <input type="submit" value={props.action} />
        </form>
    )
}

export default ProjectForm