import axios from "axios";
import React from "react";
import { login } from "../../script/login";

const MessageCard = (props) => {

    const handleDelete = (e) => {
        axios.defaults.headers.common['Authorization'] = login()
        axios.post(`https://apipambroise.lyceestvincent.fr/api/messages/delete/${e.target.getAttribute('data')}`).then(() => window.location.reload())
    }

    return (
    <div className="MessageCard">
        <div className="message-top">
            <h4>{props.message.name}</h4>
            <h4>{props.message.email}</h4>
        </div>
        <p>{props.message.message}</p>
        <div className="message-bot">
            <a onClick={handleDelete} data={props.message.id} >Supprimer</a>
        </div>
    </div>)
}

export default MessageCard