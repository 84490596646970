import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import Footer from "../../components/Footer";
import axios from "axios";
import ProjectCard from "../../components/admin/ProjectCard";
import { AiOutlinePlus } from "react-icons/ai";
import { login } from "../../script/login";

const AdminProject = () => {

    if (login() === '' ){
        window.location.replace("/admin/login")
    }

    const [projects, setProjects] = useState([])

    useEffect(() =>{
        axios.get("https://apipambroise.lyceestvincent.fr/api/projects").then((result) => {
            setProjects(result.data)
        })
    }, [])

    if (projects.length === 0){
        return <h1>Chargement</h1>
    }

    return (
        <div>
            <AdminNavbar />
                <div className="admin-project">
                    <h2 id='title-admin' >Liste des projets : </h2>
                    <div className="projects-container">
                        {projects.map((project) => 
                            <ProjectCard project={project} key={project.id} />
                        )}
                        <a href="/admin/add">
                            <div className="project-card new-project">
                                <a><AiOutlinePlus /> <span>Ajouter</span></a>
                            </div>
                        </a>
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default AdminProject