import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProjectForm from "../../components/admin/ProjectForm";
import { login } from "../../script/login";

const EditProject = () => {

    if (login() === '' ){
        window.location.replace("/admin/login")
    }
    
    let { id } = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(`https://apipambroise.lyceestvincent.fr/api/projects/${id}`)
        .then((result) => { 
            setData(result.data)}
        )
    }, [])

    const handleDelete = (e) => {
        if (window.confirm('Voulez-vous vraiment supprimer cette image ?')) {
            axios.defaults.headers.common['Authorization'] = login()
            axios.post(`https://apipambroise.lyceestvincent.fr/api/img/delete/${e.target.getAttribute('data')}`)
            .catch((er) => console.log(er))
            // window.location.reload()
        }
    }

    if (data.length === 0) {
        return (<div> Chargement </div>);
    } 

    return (
        <div>
            <AdminNavbar />
                <div className="edit-project">
                    <h2>Modifier {data.name} </h2>
                    <ProjectForm project={data} action='Modifier' />
                    <div id="form-imgs">
                        {data.images.map((image) => 
                            <div key={image.id} className="img-group">
                                <img src={`https://apipambroise.lyceestvincent.fr/images/${data.name}/${image.path}`} alt={data.name} />
                                <a onClick={handleDelete} data={image.id}>Supprimer</a>
                            </div>
                        )}
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default EditProject