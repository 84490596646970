import React from "react";
import TimelineCard from "./timeline/TimelineCard";

const timeline = () => {
    return (
        <div id='technologies' className="timeline-container">
            <div className='timeline'>
                <TimelineCard name='Python' date='Septembre 2019' src='/images/python.webp' />
                <TimelineCard name='HTML' date='Janvier 2020' src='/images/html.webp' />
                <TimelineCard name='CSS' date='Février 2020' src='/images/css.webp' />
                <TimelineCard name='SQL' date='Avril 2020' src='/images/sql.webp' />
                <TimelineCard name='C#' date='Septembre 2021' src='/images/csharp.webp' />
                <TimelineCard name='PHP' date='Décembre 2021' src='/images/php.webp' />
                <TimelineCard name='Git' date='Avril 2022' src='/images/git.webp' />
                <TimelineCard name='Symfony' date='Mai 2022' src='/images/symfony.webp' />
                <TimelineCard name='Docker' date='Janvier 2023' src='/images/docker.webp' />
                <TimelineCard name='React' date='Janvier 2023' src='/images/react.webp' />
            </div>
        </div>
    )
}

export default timeline