import axios from "axios";
import React, { useEffect, useRef } from "react";
import { login } from '../../script/login'

const ProjectCard = (props) => {

    const ref = useRef(null);

    useEffect(() =>{
        const mainDiv = ref.current
        const actions = mainDiv.querySelector('.project-actions')
        mainDiv.addEventListener('mouseenter', (e) => {
            actions.style.display = 'flex'
        })
        mainDiv.addEventListener('mouseleave', (e) => {
            actions.style.display = 'none'
        })
    }, [])

    const handleDelete = () =>{
        if (window.confirm('Voulez-vous vraiment supprimer ce projet ?')) {
            axios.defaults.headers.common['Authorization'] = login()
            axios.post(`https://apipambroise.lyceestvincent.fr/api/projects/delete/${props.project.id}`)
            .catch((er) => console.log(er))
            ref.current.style.display = 'none'
        }
    }

    return(
        <div ref={ref} className="project-card">
            <h2>{props.project.name}</h2>
            <p>{props.project.context}</p>
            <div className="project-actions">
                <a className="update" href={'/admin/project/edit/' + props.project.id}>Modifier</a>
                <a className="delete" onClick={handleDelete}>Supprimer</a>
            </div>
        </div>
    )
}

export default ProjectCard