import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { login } from "../script/login";
import axios from "axios";

const LoginForm = () => {

    if (login() !== '' ){
        window.location.replace("/admin")
    }

    const [error, setError] = useState("")

    const setLoginCookie = (token,) => {
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000); // Set the cookie expiration date to 1 hour from now
        document.cookie = `token=${token}; expires=${expirationDate.toUTCString()}`;
      }

    const handleSubmit = (form) => {
        form.preventDefault()
        const login = form.target.login.value
        const password = form.target.password.value

        if (login === "" || password === ""){
            setError("Les champs sont obligatoires")
            return
        }

        const encodedCredentials = btoa(login + ':' + password)
        const basicAuthHeader = 'Basic ' + encodedCredentials

        axios.interceptors.request.eject(axios.defaults.requestInterceptorId);
        axios.interceptors.response.eject(axios.defaults.responseInterceptorId);
        axios.defaults.headers.common['Authorization'] = basicAuthHeader
        
        axios.post('https://apipambroise.lyceestvincent.fr/api/connect')
        .then(() => {
            setLoginCookie(basicAuthHeader)
            window.location.replace("/admin")
        })       
        .catch(() => {
            setError("Identifiants incorrects")
        })
    }

    return (
        <div>
            <Navbar />
                <div id='login-container'>
                    <h2>Connectez-vous !</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="login">Nom d'utilisateur : </label>
                            <input type='text' name="login" id='login' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe : </label>
                            <input type='password' id='password' name="password" />
                        </div>
                        {error && <p className="error">{error}</p>}
                        <input id='submit' type='submit' />
                    </form>
                </div>
            <Footer />
        </div>
    )
}

export default LoginForm