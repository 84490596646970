import React, { useEffect, useRef } from "react";

const NavbarLink = () => {
    const ref = useRef(null)

    useEffect(() => {
        const links = ref.current
        links.querySelectorAll('a').forEach((a) => {
            a.addEventListener('click', (e) => {
            e.preventDefault()
            document.querySelector(e.target.hash).scrollIntoView({behavior: 'smooth', inline: "start"})
        })
        })
    }, [])

    return (
        <div ref={ref} className="navbar-links">
            <a href="#presentation" className="active">Présentation</a>
            <a href="#projects">Projets</a>
            <a href="#technologies">Technologies</a>
            <a href="#contact">Contact</a>
        </div>
    )
}

export default NavbarLink