import React from 'react';
import Navbar from '../components/Navbar';
import Project from '../components/Project'
import Timeline from '../components/Timeline';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import { AiFillGithub, AiFillMail } from "react-icons/ai";
import { CiFileOn } from "react-icons/ci";

const Home = () => {
    return (
        <div>
            <Navbar isIndex={true} />
            <div id='presentation' className='about-me'>
                <h1>Pierre Ambroise</h1>
                <h3>Développeur fullstack</h3>
                <div className='links'>
                    <a href='Pierre_Ambroise_CV.pdf' download='Pierre_Ambroise_CV.pdf' ><CiFileOn /></a>
                    <a href='https://github.com/Fan2Shrek'><AiFillGithub /></a>
                    <a href='mailto:pierre27.ambroise@gmail.com'><AiFillMail /></a>
                </div>
            </div>
            <Project />
            <Timeline />
            <Contact />
            <Footer />
        </div>
    );
};

export default Home;