import React from "react";
import AdminNavbar from "../../components/AdminNavbar";
import ProjectForm from "../../components/admin/ProjectForm";
import Footer from "../../components/Footer";
import { login } from "../../script/login";

const AddProject = () => {

    if (login() === '' ){
        window.location.replace("/admin/login")
    }

    return (        
        <div>
            <AdminNavbar />
                <div className="edit-project">
                    <h2>Ajouter un projet </h2>
                    <ProjectForm action='Ajouter' />
                </div>
            <Footer />
        </div>
    )
}

export default AddProject