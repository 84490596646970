import React from "react";

const footer = () => {
    return (
        <div className="footer">
            <h3>© Fait par Pierre Ambroise - 2023</h3>
        </div>
    )
}

export default footer