import React from "react";
import ScrollTrigger from 'react-scroll-trigger';

const TimelineCard = ( props ) => {
    const [isVisible, setIsVisible] = React.useState(false);

    function onEnterViewport() {
        setIsVisible(true);
    }

    return (
        <div className="timeLineCard">
            <h5>{props.name}</h5>
            <h6>{props.date}</h6>
            <ScrollTrigger onEnter={onEnterViewport}>
                <div className={`language-img ${isVisible ? 'language-effect' : ''}`}><img src={props.src} alt={props.name} /></div>
            </ScrollTrigger>
        </div>
    )
}

export default TimelineCard