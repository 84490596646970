import React from "react";
import { AiOutlineArrowLeft, AiFillMail, AiOutlineFundProjectionScreen } from "react-icons/ai";

const AdminNavbar = () => {
    return(
        <nav id='admin-navbar'> 
            <a id='back' href='/'><AiOutlineArrowLeft /><span>Retour au site</span></a>
            <div className="links">
                <a id='projects' href="/admin/projects"><AiOutlineFundProjectionScreen /> <span>Projets</span></a>
                <a id='mail' href="/admin/message"><AiFillMail /><span>Mail</span></a>
            </div>
        </nav>
    )
}

export default AdminNavbar