import React from "react";
import NavbarLink from "./navbar/NavbarLink";

const Navbar = ( {isIndex} ) => {

    return(
        <nav id='navbar'> 
            <a id='navbar-logo' href='/'>Pierre Ambroise</a>
            { isIndex && <NavbarLink /> }
            <a href='/admin/login' className='btn-connection'>Connexion</a>
        </nav>
    )
}

export default Navbar