import React from "react";
import Footer from "../components/Footer";
import AdminNavbar from "../components/AdminNavbar";
import { login } from "../script/login";

const Admin = () => {

    if (login() === '' ){
        window.location.replace("/admin/login")
    }

    return (
        <div>
            <AdminNavbar />
            <div className="admin-main">
                <a className="admin-action" href='/admin/projects'>
                    Voir les projets
                </a>
                <a className="admin-action" href='/admin/message'>
                    Voir les messages
                </a>
            </div>
            <Footer />
        </div>
    )
}

export default Admin