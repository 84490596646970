import React, { useState } from "react";
import axios from "axios";

const Contact = () => {

    const [errors, setErrors] = useState({})

    const handleSubmit = (form) => {
        form.preventDefault()
        const mail = form.target.mail.value
        const name = form.target.name.value
        const msg = form.target.message.value

        const data = `{"email": "${mail}", "name": "${name}", "message": "${msg}"}`
        axios.post('https://apipambroise.lyceestvincent.fr/api/message', data)
        .then((result) =>  {
            if (result.status === 201){
                setErrors({'validation': result.data})
            } else {
                setErrors(result.data)
                form.target.name.value = name
                form.target.mail.value = mail
                form.target.message.value = msg
            }})

        form.target.reset()
    }

    return (
        <div id='contact'>
            <h3>Vous souhaitez me contacter ?</h3>
            <form autoComplete="off" onSubmit={handleSubmit} >
                <div className='contact-top'>
                    <div className="form-group">
                        <input type='text' name='name' id='name' required />
                        <label htmlFor='name' title="Votre nom" data-title='Nom' ></label>
                        {errors.name && <p className="errors">{errors.name}</p>}
                    </div>
                    <div className="form-group">
                        <input type='text' name='mail' id='email' required />
                        <label htmlFor='email' title="Votre email" data-title='Email' ></label>
                        {errors.email && <p className="errors">{errors.email}</p>}
                    </div>
                </div>
                <div className="form-group">
                    <textarea name='message' placeholder="Votre message"></textarea>
                    {errors.message && <p className="errors">{errors.message}</p>}
                </div>
                <input type='submit' id='contact-submit' value='Envoyer'/>
                {errors.validation && <p className="success">{errors.validation}</p>}
            </form>
        </div>
    )
}

export default Contact