import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NotFound from "./NotFound";

const Project = () => {
    const [data, setData] = useState([])
    let { id } = useParams()

    useEffect(() => {
        axios.get(`https://apipambroise.lyceestvincent.fr/api/projects/${id}`)
        .then((result) => { 
            setData(result.data)}
        ).catch((err) => setData(404) )
    }, [id])

    if (data.length === 0){
        return (<h1>Projet non trouvé</h1>)
    }

    if (data === 404){
        return <NotFound />
    }

    return (
        <div>
            <Navbar isIndex={false} name={ data.name } />
            <div className="project-page">
                <div className="header">
                    <h2>{ data.name }</h2>
                </div>
                <div className="body">
                    <div className="description">
                        <p>{ data.description }</p>
                    </div>
                    <div className="carousel">
                        <Carousel autoPlay infiniteLoop={true} showThumbs={false}>
                            {data.images.map((image) =>
                                <div key={image.id}>
                                    <img alt={data.name} src={'https://apipambroise.lyceestvincent.fr/images/' + data.name + '/' +image.path} />
                                </div>
                            )}
                        </Carousel>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Project